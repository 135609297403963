import * as React from "react";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import './Directions.css';

// Icons
import { MdLocationOn } from "@react-icons/all-files/md/MdLocationOn";
import { MdLocalParking } from "@react-icons/all-files/md/MdLocalParking";
import { MdDirectionsCar } from "@react-icons/all-files/md/MdDirectionsCar";

// Components
import Kiku16 from '../common/icons/Kiku16';
import Kiku18 from '../common/icons/Kiku18';
import KikuLayered from '../common/icons/KikuLayered';

const Directions = (props) => {
  const breakpoints = useBreakpoint();

  const flowerSize = (size) => {
    if(breakpoints.xs) {
      return size * 0.75;
    } else if(breakpoints.sm) {
      return size * 0.85;
    } else if(breakpoints.md) {
      return size * 0.90;
    } else if(breakpoints.l) {
      return size;
    } else {
      return size;
    }
  }

  return (
    <section className="section directions" id="karta">
      <div className="directions__map">
        <iframe
          className="directions__map--google"
          frameborder="0"
          src={`https://www.google.com/maps/embed/v1/place?key=${process.env.GATSBY_GOOGLE_MAP_API}&q=Friluftsanläggningen+Bockaberg`}>
        </iframe>
      </div>
      <article className="directions__card col--c">
        <h2 className="heading--l">Hitta hit</h2>
        <div className="directions__info">
          <MdLocationOn className="directions-grid__icon" />
          <div className="directions-grid__info-wrapper col--lt-alg">
            <p>{ props.data.place }</p>
            <p>{ props.data.adress }</p>
            <p>{ props.data.zipCode } { props.data.city }</p>
          </div>
          <MdDirectionsCar className="directions-grid__icon" />
          <div className="directions-grid__info-wrapper col--lt-alg">
            <p>Ta sig hit:</p>
            <p>{ props.data.directions.car }</p>
            <p>{ props.data.directions.car2 }</p>
          </div>
          <MdLocalParking className="directions-grid__icon" />
          <div className="directions-grid__info-wrapper col--lt-alg">
            <p>Parkering:</p>
            <p>{ props.data.directions.parking }</p>
          </div>
        </div>

        <Kiku16
          size={flowerSize(80)}
          fill="red" stroke="gold"
          circleFill="white" circleStroke="white"
          classname="directions__flower directions__flower--5" />
        <Kiku16
          size={flowerSize(80)}
          fill="red" stroke="gold"
          circleFill="white" circleStroke="white"
          classname="directions__flower directions__flower--4" />
        <Kiku18
          size={flowerSize(130)}
          fill="white" stroke="red"
          circleFill="red"
          classname="directions__flower directions__flower--3" />
        <Kiku18
          size={flowerSize(130)}
          fill="white" stroke="red"
          circleFill="red"
          classname="directions__flower directions__flower--2" />
        <KikuLayered
          size={flowerSize(160)}
          fill="white" stroke="gold"
          fill2="red" stroke2="gold"
          circleFill="red" circleStroke="white"
          classname="directions__flower directions__flower--1" />
      </article>
    </section>
  )
}

export default Directions