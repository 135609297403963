import * as React from "react";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import './Boende.css';

// Components
import Kiku16 from '../common/icons/Kiku16';
import Kiku18 from '../common/icons/Kiku18';
import KikuLayered from '../common/icons/KikuLayered';

const Boende = (props) => {
  const breakpoints = useBreakpoint();

  const flowerSize = (size) => {
    if(breakpoints.xs) {
      return size * 0.55;
    } else if(breakpoints.sm) {
      return size * 0.85;
    } else if(breakpoints.md) {
      return size * 0.85;
    } else {
      return size;
    }
  }

  return (
    <section className="col--c--js-c section boende-container" id="housing">
      <h2 className="heading--l">Övernattning</h2>
      <div className="boende-card">
        <div className="boende-card__flower-container">
          <Kiku16
            size={flowerSize(80)}
            fill="white" stroke="red"
            circleFill="red"
            classname="boende__flower--5" />
          <Kiku16
            size={flowerSize(120)}
            fill="white" stroke="gold"
            circleFill="white"
            classname="boende__flower--2" />
          <Kiku16
            size={flowerSize(120)}
            fill="white" stroke="red"
            circleFill="red"
            classname="boende__flower--4" />
          <KikuLayered
            size={flowerSize(160)}
            fill="white" stroke="gold"
            fill2="red" stroke2="gold"
            circleFill="red" circleStroke="white"
            classname="boende__flower--1" />
          <KikuLayered
            size={flowerSize(60)}
            fill="white" stroke="gold"
            fill2="red" stroke2="gold"
            circleFill="red" circleStroke="white"
            classname="boende__flower--6" />
          <Kiku18
            size={flowerSize(90)}
            fill="red" stroke="gold"
            circleFill="gold"
            classname="boende__flower--3" />
        </div>
        <div className="boende-card__item col--c--js-c">
          <p className="p__end">{ props.data.housingText1 }</p>
          <p className="p__end">{ props.data.housingText2 }</p>
          <p className="p__end">{ props.data.housingText3 }</p>
          <p>{ props.data.housingText4 }</p>
        </div>
        <div className="boende-card__item boende-info col--lt-alg">
          <p>Pris: 250 kr/natt/person</p>
          <p>Frukost ingår</p>
          <p className="p__end">Utcheckning: söndag 11.00</p>
          <p>Ange vid anmälan till bröllopet om boende önskas. Betalning av boende sker via Swish till bruden (Johanna Jansson) 076-323 23 13, och skall vara inne senast 15 maj.</p>
        </div>
      </div>
    </section>
  )
}

export default Boende