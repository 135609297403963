import * as React from 'react';
import Navbar from '../components/navbar/Navbar';
import Hero from '../components/hero/Hero';
import Info from '../components/info/Info';
import Schedule from '../components/schedule/Schedule';
import Contact from '../components/contact/Contact';
import Directions from '../components/directions/Directions';
import Boende from '../components/boende/Boende';
import Faq from '../components/faq/Faq';
import Wishlist from '../components/wishlist/Wishlist';
import '../styles/global.css';

import Data from "../content/startpage_content.json";

const IndexPage = () => {
  return (
    <main>
      <title>Home Page</title>
      <Navbar />
      <Hero />
      <Info data={Data.info} />
      <Schedule data={Data.schedule} />
      <Directions data={Data.directions} />
      <Boende data={Data.housing} />
      { process.env.GATSBY_TOASTMASTER_READY === 'true' && <Contact data={Data.toastmaster} /> }
      <Faq data={Data.faq} />
      <Wishlist />
    </main>
  )
}

export default IndexPage
