import * as React from "react";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import './Faq.css';

// Components
import Kiku16 from '../common/icons/Kiku16';
import Kiku18 from '../common/icons/Kiku18';
import KikuLayered from '../common/icons/KikuLayered';

const Faq = (props) => {
  const breakpoints = useBreakpoint();

  const flowerSize = (size) => {
    if(breakpoints.xs) {
      return size * 0.55;
    } else if(breakpoints.sm) {
      return size * 0.85;
    } else if(breakpoints.md) {
      return size * 0.85;
    } else {
      return size;
    }
  }

  return (
    <section className="col--c--js-c section faq-container" id="faq">
      <h2 className="heading--l">Vanliga frågor</h2>
      <div className="faq-card">
        <div className="faq-card__flower-container">
          <Kiku16
            size={flowerSize(80)}
            fill="white" stroke="red"
            circleFill="red"
            classname="faq__flower--5" />
          <Kiku16
            size={flowerSize(120)}
            fill="white" stroke="gold"
            circleFill="white"
            classname="faq__flower--2" />
          <Kiku16
            size={flowerSize(120)}
            fill="white" stroke="red"
            circleFill="red"
            classname="faq__flower--4" />
          <KikuLayered
            size={flowerSize(160)}
            fill="white" stroke="gold"
            fill2="red" stroke2="gold"
            circleFill="red" circleStroke="white"
            classname="faq__flower--1" />
          <KikuLayered
            size={flowerSize(60)}
            fill="white" stroke="gold"
            fill2="red" stroke2="gold"
            circleFill="red" circleStroke="white"
            classname="faq__flower--6" />
          <Kiku18
            size={flowerSize(90)}
            fill="red" stroke="gold"
            circleFill="gold"
            classname="faq__flower--3" />
        </div>
        <div className="faq-card-content col--lt-alg">
          { props.data.items.map(item => {
            return <div className="faq-item">
              <p className="faq-item__question p--em">Q: {item.question}</p>
              <p className="faq-answer">A: {item.answer}</p>
            </div>
          }) }
        </div>
      </div>
    </section>
  )
}

export default Faq