import React, { useState, useEffect } from "react";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import InfoCard from './InfoCard';
import './Info.css';
import Carousel from 'react-elastic-carousel'

const Info = (props) => {
  const breakpoints = useBreakpoint();
  const [initialIndex, setInitialIndex] = useState(0);

  useEffect(() => {
    const index = getInitialIndex();
    setInitialIndex(index);
  }, []);

  const isToday = (date) => {
    const today = new Date()
    return date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
  }

  const getInitialIndex = () => {
    const weddingDay2 = new Date('2022-01-28');
    const weddingDay3 = new Date('2022-05-29');

    if(isToday(weddingDay2)) {
      return 1
    }
    if(isToday(weddingDay3)) {
      return 2
    }

    return 0
  }

  return (
    <section className="col--c--js-c section no-margin" id="info">
      <h2 className="heading--l">Info</h2>
      { breakpoints.sm ? (
        <Carousel
          itemsToShow={ breakpoints.xs ? 1 : 2}
          initialActiveIndex={initialIndex}
          renderPagination={({ pages, activePage, onClick }) => {
            return (
              <div className="info-carousel-pagination">
                {pages.map(page => {
                  const isActivePage = activePage === page;
                  let day;
                  switch(page) {
                    case 0:
                      day = 'Fredag'
                      break;
                    case 1:
                      day = 'Lördag'
                      break;
                    case 2:
                      day = 'Söndag'
                      break;
                    default:
                      day = 'day';
                      break;
                  }

                  return (
                    <button
                      key={page}
                      onClick={() => onClick(page)}
                      active={isActivePage}
                      className={`info-carousel-pagination--button ${isActivePage && 'active-carousel-page--button'}`}
                    >{day}</button>
                  )
                })}
              </div>
            )
          }}
        >
          <InfoCard heading="fredag" icon="friday" date="27 maj">
            <ul className="info-card__list">
              <li className="info-card__list-item">
                <p className="info-card__p p--em">Tid</p>
                <p className="info-card__p">{ props.data.friday.time1 }</p>
              </li>
              <li className="info-card__list-item">
                <p className="info-card__p p--em">Plats</p>
                <p className="info-card__p">{ props.data.friday.place }</p>
              </li>
              <li className="info-card__list-item">
                <p className="info-card__p p--em">Klädsel</p>
                <p className="info-card__p">{ props.data.friday.dresscode }</p>
              </li>
            </ul>
            <p className="info-card__p">{ props.data.friday.text1 }</p>
            <p>{ props.data.friday.text2 }</p>
          </InfoCard>
          <InfoCard heading="lördag" icon="saturday" date="28 maj" top={true}>
            <ul className="info-card__list">
              <li className="info-card__list-item">
                <p className="info-card__p p--em">Tid</p>
                <p className="info-card__p">{ props.data.saturday.time1 }</p>
              </li>
              <li className="info-card__list-item">
                <p className="info-card__p p--em">Plats</p>
                <p className="info-card__p">{ props.data.saturday.place1 }</p>
              </li>
              <li className="info-card__list-item">
                <p className="info-card__p p--em">Klädsel</p>
                <p className="info-card__p">{ props.data.saturday.dresscode1 }</p>
              </li>
            </ul>
            <p className="p__end">{ props.data.saturday.text1 }</p>

            <ul className="info-card__list">
              <li className="info-card__list-item">
                <p className="info-card__p p--em">Tid</p>
                <p className="info-card__p">{ props.data.saturday.time2 }</p>
              </li>
              <li className="info-card__list-item">
                <p className="info-card__p p--em">Plats</p>
                <p className="info-card__p">{ props.data.saturday.place2 }</p>
              </li>
              <li className="info-card__list-item">
                <p className="info-card__p p--em">Klädsel</p>
                <p className="info-card__p">{ props.data.saturday.dresscode2 }</p>
              </li>
            </ul>
            <p className="info-card__p">{ props.data.saturday.text2 }</p>
            <p>Hela dagens schema kan du se nedan.</p>
          </InfoCard>
          <InfoCard heading="söndag" icon="sunday" date="29 maj">
            <ul className="info-card__list">
              <li className="info-card__list-item">
                <p className="info-card__p p--em">Tid</p>
                <p className="info-card__p">{ props.data.sunday.time }</p>
              </li>
              <li className="info-card__list-item">
                <p className="info-card__p p--em">Plats</p>
                <p className="info-card__p">{ props.data.sunday.place }</p>
              </li>
            </ul>
            <p className="info-card__p">{ props.data.sunday.text }</p>
          </InfoCard>
        </Carousel>
      ): (
      <div className="full-w info-card__wrapper">
        { breakpoints.sm ? <span className="spacer" /> : null}
        <InfoCard heading="fredag" icon="friday" date="27 maj">
          <ul className="info-card__list">
            <li className="info-card__list-item">
              <p className="info-card__p p--em">Tid</p>
              <p className="info-card__p">{ props.data.friday.time1 }</p>
            </li>
            <li className="info-card__list-item">
              <p className="info-card__p p--em">Plats</p>
              <p className="info-card__p">{ props.data.friday.place }</p>
            </li>
            <li className="info-card__list-item">
              <p className="info-card__p p--em">Klädsel</p>
              <p className="info-card__p">{ props.data.friday.dresscode }</p>
            </li>
          </ul>
          <p className="info-card__p">{ props.data.friday.text1 }</p>
          <p>{ props.data.friday.text2 }</p>
        </InfoCard>
        <InfoCard heading="lördag" icon="saturday" date="28 maj" top={true}>
          <ul className="info-card__list">
            <li className="info-card__list-item">
              <p className="info-card__p p--em">Tid</p>
              <p className="info-card__p">{ props.data.saturday.time1 }</p>
            </li>
            <li className="info-card__list-item">
              <p className="info-card__p p--em">Plats</p>
              <p className="info-card__p">{ props.data.saturday.place1 }</p>
            </li>
            <li className="info-card__list-item">
              <p className="info-card__p p--em">Klädsel</p>
              <p className="info-card__p">{ props.data.saturday.dresscode1 }</p>
            </li>
          </ul>
          <p className="p__end">{ props.data.saturday.text1 }</p>

          <ul className="info-card__list">
            <li className="info-card__list-item">
              <p className="info-card__p p--em">Tid</p>
              <p className="info-card__p">{ props.data.saturday.time2 }</p>
            </li>
            <li className="info-card__list-item">
              <p className="info-card__p p--em">Plats</p>
              <p className="info-card__p">{ props.data.saturday.place2 }</p>
            </li>
            <li className="info-card__list-item">
              <p className="info-card__p p--em">Klädsel</p>
              <p className="info-card__p">{ props.data.saturday.dresscode2 }</p>
            </li>
          </ul>
          <p className="info-card__p">{ props.data.saturday.text2 }</p>
          <p>Hela dagens schema kan du se nedan.</p>
        </InfoCard>
        <InfoCard heading="söndag" icon="sunday" date="29 maj">
          <ul className="info-card__list">
            <li className="info-card__list-item">
              <p className="info-card__p p--em">Tid</p>
              <p className="info-card__p">{ props.data.sunday.time }</p>
            </li>
            <li className="info-card__list-item">
              <p className="info-card__p p--em">Plats</p>
              <p className="info-card__p">{ props.data.sunday.place }</p>
            </li>
          </ul>
          <p className="info-card__p">{ props.data.sunday.text }</p>
        </InfoCard>
        { breakpoints.sm ? <span className="spacer" /> : null}
      </div>
      )}
    </section>
  )
}

export default Info