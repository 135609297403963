import * as React from "react";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import './Hero.css';
import Kiku16 from '../common/icons/Kiku16';
import Kiku18 from '../common/icons/Kiku18';
import KikuLayered from '../common/icons/KikuLayered';

const Hero = () => {
  const breakpoints = useBreakpoint();
  const flowerSize = (size) => {
    if(breakpoints.xs) {
      return size * 0.4;
    } else if(breakpoints.sm) {
      return size * 0.85;
    } else if(breakpoints.md) {
      return size * 0.85;
    } else {
      return size;
    }
  }

  return (
    <section className="center section hero">
      <div className="hero__card col--c--js-c">
          <Kiku18 size={flowerSize(200)} fill="red" stroke="gold" circleFill="gold" classname="hero__flower--8" />
          <KikuLayered size={flowerSize(140)} fill="white" stroke="gold" fill2="red" stroke2="gold" circleFill="red" circleStroke="red" classname="hero__flower--9" />
          <Kiku16 size={flowerSize(125)} fill="white" stroke="gold" circleFill="gold" classname="hero__flower--10" />
          <Kiku18 size={flowerSize(78)} fill="red" stroke="gold" circleFill="white" circleStroke="white" classname="hero__flower--11" />
          <Kiku16 size={flowerSize(110)} fill="white" stroke="red" circleFill="red" classname="hero__flower--12" />

          <Kiku16 size={flowerSize(105)} fill="red" stroke="gold" circleFill="white" circleStroke="white" classname="hero__flower--3" />
          <Kiku18 size={flowerSize(150)} fill="white" stroke="gold" circleFill="red" circleStroke="red" classname="hero__flower--2" />
          
          <Kiku16 size={flowerSize(105)} fill="white" stroke="red" circleFill="red" classname="hero__flower--5" />
          <Kiku16 size={flowerSize(170)} fill="white" stroke="gold" circleFill="white" classname="hero__flower--4" />
          <KikuLayered size={flowerSize(90)} fill="red" stroke="gold" fill2="red" stroke2="gold" circleFill="white" circleStroke="white" classname="hero__flower--6" />
          <Kiku18 size={flowerSize(125)} fill="red" stroke="gold" circleFill="gold" classname="hero__flower--7" />
          
          <KikuLayered size={flowerSize(240)} fill="white" stroke="gold" fill2="red" stroke2="gold" circleFill="gold" circleStroke="white" classname="hero__flower--1" />
        <h1>Bjarne & Johanna</h1>
        <p className="hero-sub-title">28 maj 2022</p>
      </div>
    </section>
  )
}

export default Hero