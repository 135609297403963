import * as React from "react";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { StaticImage } from "gatsby-plugin-image";
import './Contact.css';

// Components
import Kiku16 from '../common/icons/Kiku16';
import Kiku18 from '../common/icons/Kiku18';
import KikuLayered from '../common/icons/KikuLayered';

const Contact = (props) => {
  const breakpoints = useBreakpoint();

  const flowerSize = (size) => {
    if(breakpoints.xs) {
      return size * 0.55;
    } else if(breakpoints.sm) {
      return size * 0.55;
    } else if(breakpoints.md) {
      return size * 0.85;
    } else {
      return size;
    }
  }

  return (
    <section className="col--c--js-c section" id="toastmaster">
      <h2 className="heading--l">Toastmaster</h2>
      <div className="contact-card center">
        <Kiku16
          size={flowerSize(80)}
          fill="white" stroke="red"
          circleFill="red"
          classname="contact__flower--5" />
        <Kiku16
          size={flowerSize(120)}
          fill="white" stroke="gold"
          circleFill="white"
          classname="contact__flower--2" />
        <Kiku16
          size={flowerSize(120)}
          fill="white" stroke="red"
          circleFill="red"
          classname="contact__flower--4" />
        <KikuLayered
          size={flowerSize(160)}
          fill="white" stroke="gold"
          fill2="red" stroke2="gold"
          circleFill="red" circleStroke="white"
          classname="contact__flower--1" />
        <KikuLayered
          size={flowerSize(60)}
          fill="white" stroke="gold"
          fill2="red" stroke2="gold"
          circleFill="red" circleStroke="white"
          classname="contact__flower--6" />
        <Kiku18
          size={flowerSize(90)}
          fill="red" stroke="gold"
          circleFill="gold"
          classname="contact__flower--3" />

        <div className="contact-card__item toastmaster-anmalan col--c--js-c">
          <p>{ props.data.rsvpText1 }</p>
          <p className="p__end">{ props.data.rsvpText2 }</p>
          <a href="mailto: toastmaster.bjarneochjohanna@gmail.com" className="p__end toast_email">toastmaster.bjarneochjohanna@gmail.com</a>
        </div>
        <div className="contact-card__item contact-toastmaster col--c--js-c">
          <p className="p__end contact-toastmaster-intro">{ props.data.toastmasterText1 }</p>
          <div className="contact-toastmaster__container full-w">
            <div className="contact-toasmaster-profile col--c">
              <div className="contact-toasmaster__img">
                <StaticImage
                  src="../../images/nicke.jpg"
                  quality={90}
                  alt="Underwater view of clear, blue body of water"
                />
              </div>
              <p className="p--em">{ props.data.toastmasters.toastmaster1.name }</p>
              <p className="contact-toastmaster-presentation">{ props.data.toastmasters.toastmaster1.presentation }</p>
            </div>
            <div className="contact-toasmaster-profile col--c">
              <div className="contact-toasmaster__img">
                <StaticImage
                  src="../../images/emelie.jpg"
                  quality={90}
                  alt="Underwater view of clear, blue body of water"
                />
              </div>
              <p className="p--em">{ props.data.toastmasters.toastmaster2.name }</p>
              <p className="contact-toastmaster-presentation">{ props.data.toastmasters.toastmaster2.presentation }</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact