import * as React from "react";
import './schedule.css';

const ScheduleCard = (props) => (
  <div className={`schedule-card__container col--c ${props.item}`}>
    <span className="schedule-card__thread" style={{height: `${props.lineHeight}vh`}}></span>
    <article className="schedule-card col--c--js-c">
      <h3 className="heading--s">{ props.title }</h3>
      <p>{ props.text }</p>
    </article>
  </div>
)

export default ScheduleCard