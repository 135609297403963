import * as React from "react";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import './InfoCard.css';

// Icons
import { GiCoffeeCup } from 'react-icons/gi';
import { GiLinkedRings } from 'react-icons/gi';
import { GiBarbecue } from 'react-icons/gi';

// Utils
import Palette from '../../utils/palette';

// Components
import Kiku16 from '../common/icons/Kiku16';
import Kiku18 from '../common/icons/Kiku18';
import KikuLayered from '../common/icons/KikuLayered';

const InfoCard = React.forwardRef((props, ref) => {
  const breakpoints = useBreakpoint();

  const renderIcon = () => {
    switch(props.icon) {
      case "friday":
        return <GiBarbecue className="info-card__img" color={Palette.red} />
      case "saturday":
        return <GiLinkedRings className="info-card__img" color={Palette.red} />
      case "sunday":
        return <GiCoffeeCup className="info-card__img" color={Palette.red} />     
    }
  }

  const flowerSize = (size) => {
    if(breakpoints.xs) {
      return size * 0.60;
    } else if(breakpoints.sm) {
      return size * 0.60;
    } else if(breakpoints.md) {
      return size * 0.75;
    } else if(breakpoints.l) {
      return size * 0.95;
    } else {
      return size;
    }
  }

  return (
    <article className="info-card col--c" ref={ref}>
      <Kiku18
        size={flowerSize(134)}
        fill="red" stroke="gold"
        circleFill="white" circleStroke="white"
        classname={ props.top ? "info__flower--top-1" : "info__flower--1" } />
      <Kiku16
        size={flowerSize(70)}
        fill="white" stroke="red"
        circleFill="red"
        classname={ props.top ? "info__flower--top-4" : "info__flower--4" } />
      <KikuLayered
        size={flowerSize(90)}
        fill="white" stroke="gold"
        fill2="red" stroke2="gold"
        circleFill="red" circleStroke="white"
        classname={ props.top ? "info__flower--top-3" : "info__flower--3" } />
      <Kiku16
        size={flowerSize(95)}
        fill="white" stroke="gold"
        circleFill="red" circleStroke="red"
        classname={ props.top ? "info__flower--top-2" : "info__flower--2" } />
      
      { renderIcon() }
      <h3 className="heading--m">{props.heading}</h3>
      <p className="info-card__sub-heading">{props.date}</p>
      {props.children}
    </article>
  )
})

export default InfoCard