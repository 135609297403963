import * as React from "react";
import ScheduleCard from './ScheduleCard';
import './schedule.css';

const Schedule = (props) => (
  <section className="section schedule" id="schema">
    <ScheduleCard
      item="schedule-card--card1"
      lineHeight={20}
      title={props.data.lunch.title}
      text={props.data.lunch.text}
    />
    <ScheduleCard
      item="schedule-card--card2"
      lineHeight={20}
      title={props.data.dinner.title}
      text={props.data.dinner.text}
    />
    <ScheduleCard
      item="schedule-card--card3"
      lineHeight={60}
      title={props.data.activity.title}
      text={props.data.activity.text}
    />
    <ScheduleCard
      item="schedule-card--card4"
      lineHeight={55}
      title={props.data.reception.title}
      text={props.data.reception.text}
    />
    <ScheduleCard
      item="schedule-card--card5"
      lineHeight={60}
      title={props.data.party.title}
      text={props.data.party.text}
    />
  </section>
)

export default Schedule